<template>

    <!-- inicio siguenos en instagram -->
    <section class="titulos4">
        <div class="contenedor-titulos scroll-up">
            <img src="../assets/img/fondo-titulos.png" alt="">
            <div class="texto"><h2>Síguenos En Instagram</h2></div>
        </div>
        
        <a class="link-instagram scroll-up" target="_blank" href="https://www.instagram.com/glamourbyramos/">@glamourbyramos</a>

        <div class="contenedor-siguenos">
            <div class="imagenes-siguenos scroll-up">
                <figure>
                    <img v-lazy="require('@/assets/img/glamourbyramos-instagram-1.jpg')" alt="hermosos artículos en glamour by ramos, síguenos en instagram" class="projects__img img-welcome">
                </figure>
            </div>

            <div class="imagenes-siguenos scroll-up">
                <figure>
                    <img v-lazy="require('@/assets/img/glamourbyramos-instagram-2.jpg')" alt="hermosos artículos en glamour by ramos, síguenos en instagram" class="projects__img img-welcome">
                </figure>
            </div>

            <div class="imagenes-siguenos scroll-up">
                <figure>
                    <img v-lazy="require('@/assets/img/glamourbyramos-instagram-3.jpg')" alt="hermosos artículos en glamour by ramos, síguenos en instagram" class="projects__img img-welcome">
                </figure>
            </div>

            <div class="imagenes-siguenos scroll-up">
                <figure>
                    <img v-lazy="require('@/assets/img/glamourbyramos-instagram-4.jpg')" alt="hermosos artículos en glamour by ramos, síguenos en instagram" class="projects__img img-welcome">
                </figure>
            </div>

            <div class="imagenes-siguenos scroll-up">
                <figure>
                    <img v-lazy="require('@/assets/img/glamourbyramos-instagram-5.jpg')" alt="hermosos artículos en glamour by ramos, síguenos en instagram" class="projects__img img-welcome">
                </figure>
            </div>

            <div class="imagenes-siguenos scroll-up">
                <figure>
                    <img v-lazy="require('@/assets/img/glamourbyramos-instagram-6.jpg')" alt="hermosos artículos en glamour by ramos, síguenos en instagram" class="projects__img img-welcome">
                </figure>
            </div>
        </div>

        <!-- <section class="imagen-light">
            <img src="" alt="" class="agregar-imagen">
        </section> -->
    </section>
    <!-- final siguenos en instagram -->

</template>


<script>
</script>


<style lang="scss">
    @import "@/assets/styles/normalize.css";
    @import "@/assets/styles/variables.scss";
    @import "@/assets/styles/fonts.scss";

    //siguenos en instagram
    .titulos4{
    background-image: url(../assets/img/fondito.png);
    background-position: top;
    background-repeat: no-repeat;
    // background-attachment: fixed;
    background-size: cover ;
    padding:0.8rem 3%;
    
    .contenedor-titulos{
        position: relative;
        display: inline-block;
        text-align: center;
        display: flex;
        justify-content: center;

        img{
            height: 8rem;
            width: 40rem;
        }

        .texto{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;

            h2{
                color: $violeta-titulos;
                // font-family: 'Roboto', sans-serif;
                font-size: 2.8rem;
                // font-family: 'Crete Round', serif;
                font-family: 'Lobster', cursive;
                font-weight: 300;
                letter-spacing: 1px;
            }
        }
    }
}


.titulos4{

.link-instagram{
    display: flex;
    justify-content: center;
    font-size: 2.8rem;
    color: $dorado;
    margin: .5rem 0;
    font-family: 'Lobster', cursive;
    font-weight: 300;
    letter-spacing: 3px;

    &:hover{
        transform: scale(1.05);
    }
}

.contenedor-siguenos{
    margin-top: 3rem;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .imagenes-siguenos{
        margin: 0 auto;
        align-items: center;

        position: relative;
        overflow: hidden;

        &:hover{
            transform: scale(1.02);
        }

        .projects__img{
            object-fit:cover;
            border-radius: 3px;
            cursor: pointer;
            overflow: hidden;
        }

        img{
            height: 40rem;
            width: 35rem;
            object-fit: cover;
            margin-bottom: 3rem;
        }
    }
}
}


@media (max-width:768px){
    .titulos4{
        padding: 0 3%;
        background-image: url(../assets/img/fondito2.png);
      }
}

@media (max-width:700px){
    .titulos4 .contenedor-siguenos .imagenes-siguenos img{
        height: 22rem;
        width: 20rem;
      }
}

</style>