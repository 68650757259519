<template>
    <navCategoria/>
    <Enlaces/>
  
    <div class="productos-container">
      <!-- <h1>{{ titulo }}</h1> -->
      <div class="contenedor-titulos scroll-up">
              <img src="@/assets/img/fondo-titulos.png" alt="">
              <div class="texto"><h2>{{titulo}}</h2></div>
      </div>
      <div class="productos">
        <div class="producto" v-for="producto in productos" :key="producto.id">
          <div class="img-container">
            <img :src="producto.imagen" :alt="producto.nombre">
            <div class="icon-eye" @click="abrirModalInfo(producto)">
              <i class="bx bx-show"></i>
            </div>
          </div>
          <p class="ref">{{ producto.referencia }}</p>
          <p class="nombre-producto">{{ producto.nombre }}</p>
          <p class="precio">{{ producto.precio }}</p>
          <div class="cantidad">
            <button @click="decrementarCantidad(producto)">-</button>
            <span>{{ producto.cantidad }}</span>
            <button @click="incrementarCantidad(producto)">+</button>
          </div>
          <button @click="agregarAlCarrito(producto)">Agregar al carrito</button>
        </div>
      </div>
      
      <!-- Modal para agregar al carrito -->
      <div v-if="mostrarModalCarrito" class="modal">
        <div class="modal-content">
          <span class="close" @click="cerrarModalCarrito">&times;</span>
          <p>Producto agregado al carrito</p>
        </div>
      </div>
      
      <!-- Modal para información del producto -->
      <div v-if="mostrarModalInfo && productoSeleccionado" class="modal">
        <div class="modal-content">
          <span class="close" @click="cerrarModalInfo">&times;</span>
          <img :src="productoSeleccionado.imagen" :alt="productoSeleccionado.nombre">
          <p>Descripción</p>
          <li>{{ productoSeleccionado.descripcion }}</li>
        </div>
      </div>
    </div>
    <Footer/> 
  </template>
  
  
  
  
  <script>
  import NavCategoria from '@/components/navCategoria.vue'
  import Enlaces from '@/components/enlaces.vue'
  import Footer from '@/components/footer.vue'
  
  export default {
    components: {
      NavCategoria,
      Enlaces,
      Footer,
    },
    data() {
      return {
        titulo: "Charms de Perlas de Colores",
        productos: [
          {
            id: 1,
            referencia: "ref: GBR-RPDL-T002-04",
            nombre: "Charms en acero C-U",
            precio: "C$42.00",
            imagen: require('@/assets/img/charms/charms_de_perlas_de_colores/1-GBR-RPDL-T002-04. Charms en acero inoxidable perlas y escharche. C$75.00 C-U.png'),
            descripcion: "Charms en acero inoxidable perlas y escharche. C$75.00 C-U.",
            seccion: "sección charms de perlas de colores",
            cantidad: 1
          },
          {
            id: 2,
            referencia: "ref: GBR-RPDL-T003-08",
            nombre: "Charms en acero C-U",
            precio: "C$75.00",
            imagen: require('@/assets/img/charms/charms_de_perlas_de_colores/2-GBR-RPDL-T003-08. Charms en Acero Inoxidables, colores solidos combinados. C$75.00 c-U.png'),
            descripcion: "Charms en Acero Inoxidables, colores solidos combinados. C$75.00 C-U",
            seccion: "sección charms de perlas de colores",
            cantidad: 1
          },
        ],
        mostrarModalCarrito: false,
        mostrarModalInfo: false,
        productoSeleccionado: null,
      }
    },
    methods: {
      incrementarCantidad(producto) {
        producto.cantidad++;
      },
      decrementarCantidad(producto) {
        if (producto.cantidad > 1) {
          producto.cantidad--;
        }
      },
      agregarAlCarrito(producto) {
        this.$store.commit('agregarAlCarrito', { ...producto });
        this.mostrarModalCarrito = true;
        setTimeout(() => {
          this.cerrarModalCarrito();
        }, 1000); // Modal se cierra automáticamente después de 1 segundo
      },
      abrirModalInfo(producto) {
        this.productoSeleccionado = producto;
        this.mostrarModalInfo = true;
      },
      cerrarModalCarrito() {
        this.mostrarModalCarrito = false;
      },
      cerrarModalInfo() {
        this.mostrarModalInfo = false;
        this.productoSeleccionado = null;
      }
    }
  }
  </script>
  
  
  
  <style lang="scss">
  @import "@/assets/styles/stylesProductos.scss";
  
  .contenedor-titulos{
          position: relative;
          display: inline-block;
          text-align: center;
          display: flex;
          justify-content: center;
  
          img{
              height: 8rem;
              width: 40rem;
          }
  
          .texto{
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 100%;
  
              h2{
                  color: $violeta-titulos;
                  // font-family: 'Roboto', sans-serif;
                  font-size: 2.8rem;
                  // font-family: 'Crete Round', serif;
                  font-family: 'Lobster', cursive;
                  font-weight: 300;
                  letter-spacing: 1px;
              }
          }
  
      }
  
  .productos-container {
    padding: 10px;
  
    .productos {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
  
      .producto {
        margin: 20px;
  
        .img-container {
          position: relative;
  
          img {
            height: 29rem;
            width: 24rem;
            object-fit: cover; // Asegura que la imagen mantenga sus proporciones dentro del contenedor
          }
  
          .icon-eye {
            position: absolute;
            bottom: 10px;
            right: 10px;
            background-color: rgba(0, 0, 0, 0.5);
            padding: 5px;
            border-radius: 50%;
            cursor: pointer;
  
            i {
              color: #fff;
              font-size: 18px;
            }
          }
        }
  
        // .ref, .nombre-producto, .precio, .cantidad, button {
        //   text-align: center;
        //   font-size: 16px;
        // }
      }
    }
  
    .modal {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.7);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1000;
      padding-top: 130px;
  
      .modal-content {
        background-color: #fff;
        padding: 35px 13px;
        border-radius: 8px;
        text-align: center;
        position: relative;
        max-width: 350px;
        width: 90%;
        // margin-top: 100px;
  
        p{
          color: $violeta;
          font-weight:700;
          font-size: 1.7rem;
          margin: -.5rem 0 .5rem 0;
        }
  
        li{
          font-size: 1.5rem;
        }
  
        img {
          width: 80%;
          height: 30rem;
          margin-bottom: 15px;
          object-fit: cover;
          // display: none;
        }
  
        .close {
          position: absolute;
          top: 10px;
          right: 10px;
          font-size: 24px;
          cursor: pointer;
        }
      }
    }
  
    @media (max-width: 768px) {
      .productos {
        flex-direction: column;
        align-items: center;
  
        .producto {
          width: 100%;
          margin: 10px 0;
  
          .img-container {
            img {
              width: 100%;  // Ajusta el ancho de la imagen según el contenedor
              height: auto; // Mantén la proporción de la imagen
            }
            .icon-eye {
              bottom: 5px;
              right: 5px;
              padding: 3px;
              i {
                font-size: 16px;
              }
            }
          }
  
          .ref, .nombre-producto, .precio, .cantidad, button {
            font-size: 14px;
          }
        }
      }
  
      .modal .modal-content {
        width: 95%;
        padding: 15px;
      }
    }
  
    @media (max-width: 700px) {
      .productos {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
  
        .producto {
          width: 48%;
          margin: 10px 0;
  
          .img-container {
            img {
              width: 100%;  // Ajusta el ancho de la imagen según el contenedor
              height: auto; // Mantén la proporción de la imagen
            }
            .icon-eye {
              bottom: 5px;
              right: 5px;
              // padding: 2px;
              // i {
              //   font-size: 16px;
              // }
            }
          }
  
          .ref, .nombre-producto, .precio, .cantidad, button {
            font-size: 14px;
          }
        }
      }
  
      .modal{
        padding-top: 80px;
  
        .modal-content {
          width: 100%;
          padding: 10px;
  
        p{
          font-size: 2rem;
        }  
  
        li{
          margin: 1rem 0;
          font-size: 1.8rem;
        }
      }
      } 
    }
  
    @media (max-width: 450px) {
      .productos {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
  
        .producto {
          width: 48%;
          margin: 5px 0;
  
          .img-container {
            .icon-eye {
              bottom: 3px;
              right: 3px;
              // padding: 2px;
              // i {
              //   font-size: 14px;
              // }
            }
          }
  
          .ref, .nombre-producto, .precio, .cantidad, button {
            font-size: 12px;
          }
        }
      }
    }
  
    @media (max-width: 400px){
      .modal .modal-content{
        width: 70%;
  
        img{
        width: 80%;  // Cambia el tamaño de la imagen al 70% del contenedor
        height: 25rem;
        padding:2rem 0 .5rem 0;
      }
      } 
    }
  }
  </style>