<template>

  
    <navCategoria/>
    <Enlaces/>


    <div class="carrito-container">
      <h1>Carrito de Compras</h1>
      <div v-if="carrito.length === 0">
        <p>No hay productos en el carrito.</p>
      </div>
      <div class="items-carrito" v-else>
        <div class="producto" v-for="producto in carrito" :key="producto.id">
          <p>{{ producto.referencia }}</p>
          <p>{{ producto.nombre }}</p>
          <p>{{ producto.precio }}</p>
          <p>Cantidad: {{ producto.cantidad }}</p>
          <button class="eliminar-button" @click="eliminarDelCarrito(producto)">Eliminar del carrito</button>
        </div>
        <p class="total">Total: C${{ calcularTotal() }}</p>
        <button class="comprar-button" @click="comprar()">Comprar</button>
      </div>
    </div>
  </template>
  
  <script>
    import NavCategoria from '@/components/navCategoria.vue'
    import Enlaces from '@/components/enlaces.vue'

  export default {
    components: {
    NavCategoria,
    Enlaces,
  },

    computed: {
      carrito() {
        return this.$store.state.carrito;
      }
    },
    methods: {
      eliminarDelCarrito(producto) {
        this.$store.commit('eliminarDelCarrito', producto);
      },
      calcularTotal() {
        let total = 0;
        this.carrito.forEach(producto => {
          total += parseFloat(producto.precio.replace('C$', '')) * producto.cantidad;
        });
        return total.toFixed(2);
      },
      comprar() {
        // Crear el mensaje de WhatsApp
        let mensaje = "Hola, estoy interesado en comprar los siguientes productos:\n";
        this.carrito.forEach(producto => {
          mensaje += `${producto.seccion} - ${producto.referencia} - ${producto.nombre} - ${producto.precio} - Cantidad: ${producto.cantidad}\n`;
        });
  
        // Agregar el total al mensaje
        mensaje += `\nTotal: C$${this.calcularTotal()}`;
        
        // Codificar el mensaje para incluirlo en la URL
        let mensajeCodificado = encodeURIComponent(mensaje);
        
        // Número de WhatsApp al que se enviará el mensaje
        let numeroWhatsApp = "+573172171691"; // Cambiar al número deseado
        
        // Crear la URL de WhatsApp
        let url = `https://api.whatsapp.com/send?phone=${numeroWhatsApp}&text=${mensajeCodificado}`;
        
        // Redirigir a WhatsApp
        window.open(url, '_blank');
      }
    }
  }
  </script>
  
  <style scoped lang="scss"> 
    @import "@/assets/styles/normalize.css";
    @import "@/assets/styles/variables.scss";
    @import "@/assets/styles/fonts.scss";


  .carrito-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;

    h1{
      color: $violeta;
      font-size: 2.5rem;
    }

    p{
      font-size: 1.5rem;
    }

    .items-carrito .total{
      margin-top: 4rem;
      font-size: 2rem;
    }
  }
  
  .productos {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .producto {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
    width: 50%;
    margin: 10px auto;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .producto p {
    margin: 5px 0;
  }
  
  .eliminar-button{
    background-color: $violeta;
    color: white;
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 8px;
  }
  
  .comprar-button {
    background-color: $violeta;
    color: white;
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 1rem 0;
    cursor: pointer;
    border-radius: 8px;
  }
  
  .comprar-button:hover {
    background-color: #45a049;
  }
  
  .total {
    font-weight: bold;
    margin-bottom: 20px;
  }

  @media (max-width:650px){
    .producto{
      width: 70%;
    }
  }

  @media (max-width:450px){
    .eliminar-button{
      font-size: 13px;
    }

    .comprar-button{
      font-size: 13px;
    }
  }
  </style>
  