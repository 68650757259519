<template>
  <navCategoria/>
  <Enlaces/>

  <div class="productos-container">
    <!-- <h1>{{ titulo }}</h1> -->

    <div class="contenedor-titulos scroll-up">
            <img src="@/assets/img/fondo-titulos.png" alt="">
            <div class="texto"><h2>{{titulo}}</h2></div>
    </div>

    <div class="productos">
      <div class="producto" v-for="producto in productos" :key="producto.id">
        <div class="img-container">
          <img :src="producto.imagen" :alt="producto.nombre">
          <div class="icon-eye" @click="abrirModalInfo(producto)">
            <i class="bx bx-show"></i>
          </div>
        </div>
        <p class="ref">{{ producto.referencia }}</p>
        <p class="nombre-producto">{{ producto.nombre }}</p>
        <p class="precio">{{ producto.precio }}</p>
        <div class="cantidad">
          <button @click="decrementarCantidad(producto)">-</button>
          <span>{{ producto.cantidad }}</span>
          <button @click="incrementarCantidad(producto)">+</button>
        </div>
        <button @click="agregarAlCarrito(producto)">Agregar al carrito</button>
      </div>
    </div>
    
    <!-- Modal para agregar al carrito -->
    <div v-if="mostrarModalCarrito" class="modal">
      <div class="modal-content">
        <span class="close" @click="cerrarModalCarrito">&times;</span>
        <p>Producto agregado al carrito</p>
      </div>
    </div>
    
    <!-- Modal para información del producto -->
    <div v-if="mostrarModalInfo && productoSeleccionado" class="modal">
      <div class="modal-content">
        <span class="close" @click="cerrarModalInfo">&times;</span>
        <img :src="productoSeleccionado.imagen" :alt="productoSeleccionado.nombre">
        <p>Descripción</p>
        <li>{{ productoSeleccionado.descripcion }}</li>
      </div>
    </div>
  </div>

  <Footer/> 
</template>




<script>
import NavCategoria from '@/components/navCategoria.vue'
import Enlaces from '@/components/enlaces.vue'
import Footer from '@/components/footer.vue'


export default {
  components: {
    NavCategoria,
    Enlaces,
    Footer,
  },
  data() {
    return {
      titulo: "Aretes",
      productos: [
        {
          id: 1,
          referencia: "ref: GBR-EJEW-A056-07G",
          nombre: "Argolla enchapada en oro",
          precio: "C$235.00",
          imagen: require('@/assets/img/aretes/1-GBR-EJEW-A056-07G. Argolla enchapada en oro 18K, medio aro triple. C$235.00.png'),
          descripcion: "Argolla enchapada en oro 18K, medio aro triple",
          seccion: "sección aretes",
          cantidad: 1
        },
        {
          id: 2,
          referencia: "ref: GBR-EJEW-AN0001-30-G",
          nombre: "Pendientes trepadores",
          precio: "C$245.00",
          imagen: require('@/assets/img/aretes/2-GBR-EJEW-AN0001-30-G. Pendientes trepadores de cornamenta de aleación, para mujeres, platino y dorado. C$245.00.png'),
          descripcion: "Pendientes trepadores de cornamenta de aleación, para mujeres, platino y dorado.",
          seccion: "sección aretes",
          cantidad: 1
        },
        {
          id: 3,
          referencia: "ref: GBR-EJEW-AN0001-30-P",
          nombre: "Pendientes trepadores",
          precio: "C$245.00",
          imagen: require('@/assets/img/aretes/3-GBR-EJEW-AN0001-30-P. Pendientes trepadores de cornamenta de aleación, para mujeres, platino y plateado. C$245.00.png'),
          descripcion: "Pendientes trepadores de cornamenta de aleación, para mujeres, platino y plateado.",
          seccion: "sección aretes",
          cantidad: 1
        },
        {
          id: 4,
          referencia: "ref: GBR-EJEW-AN0001-61",
          nombre: "Pendientes trepadores",
          precio: "C$245.00",
          imagen: require('@/assets/img/aretes/4-GBR-EJEW-AN0001-61. Pendientes trepadores de flor con cristales de imitación y esmalte, con borlas de cadena de aleación, para mujeres, dorado. C$245.00.png'),
          descripcion: "Pendientes trepadores de flor con cristales de imitación y esmalte, con borlas de cadena de aleación, para mujeres, dorado.",
          seccion: "sección aretes",
          cantidad: 1
        },
        {
          id: 5,
          referencia: "ref: GBR-EJEW-AN0001-61",
          nombre: "Pendientes trepadores",
          precio: "C$285.00",
          imagen: require('@/assets/img/aretes/5-GBR-EJEW-AN0001-61. Pendientes trepadores de flor con cristales de imitación y esmalte, con borlas de cadena de aleación, para mujeres, dorado. C$285.00.png'),
          descripcion: "Pendientes trepadores de flor con cristales de imitación y esmalte, con borlas de cadena de aleación, para mujeres, dorado.",
          seccion: "sección aretes",
          cantidad: 1
        },
        {
          id: 6,
          referencia: "ref: GBR-EJEW-AN0003-81",
          nombre: "Pendientes trepadores",
          precio: "C$235.00",
          imagen: require('@/assets/img/aretes/6-GBR-EJEW-AN0003-81. Pendientes trepadores de mariposa de aleación con cristales de imitación, para mujeres y niñas, platino. C$235.00.png'),
          descripcion: "Pendientes trepadores de mariposa de aleación con cristales de imitación, para mujeres y niñas, platino.",
          seccion: "sección aretes",
          cantidad: 1
        },
        {
          id: 7,
          referencia: "ref: GGBR-EJEW-AN0003-81-G",
          nombre: "Pendientes trepadores",
          precio: "C$285.00",
          imagen: require('@/assets/img/aretes/7-GBR-EJEW-AN0003-81-G. Pendientes trepadores de mariposa de aleación con cristales de imitación, para mujeres y niñas, dorado. C$285.00.png'),
          descripcion: "Pendientes trepadores de mariposa de aleación con cristales de imitación, para mujeres y niñas, dorado.",
          seccion: "sección aretes",
          cantidad: 1
        },
        {
          id: 8,
          referencia: "ref: GBR-EJEW-AN0003-81-P",
          nombre: "Pendientes trepadores",
          precio: "C$285.00",
          imagen: require('@/assets/img/aretes/8-GBR-EJEW-AN0003-81-P. Pendientes trepadores de mariposa de aleación con cristales de imitación, para mujeres y niñas, platino. C$285.00.png'),
          descripcion: "Pendientes trepadores de mariposa de aleación con cristales de imitación, para mujeres y niñas, platino.",
          seccion: "sección aretes",
          cantidad: 1
        },
        {
          id: 11,
          referencia: "ref: GBR-EJEW-C008-01G",
          nombre: "Aretes de botón colgantes",
          precio: "C$317.00",
          imagen: require('@/assets/img/aretes/11-GBR-EJEW-C008-01G. Aretes de botón colgantes con forma de corazón hueco de circonita cúbica transparente, joyería para mujer, chapado en oro real de 18 quilates, 23 mm. C$317.00.png'),
          descripcion: "Aretes de botón colgantes con forma de corazón hueco de circonita cúbica transparente, joyería para mujer, chapado en oro real de 18 quilates, 23 mm.",
          seccion: "sección aretes",
          cantidad: 1
        },
        {
          id: 12,
          referencia: "ref: GBR-EJEW-C035-12G",
          nombre: "Aretes forma de lágrina",
          precio: "C$285.00",
          imagen: require('@/assets/img/aretes/12-GBR-EJEW-C035-12G. Aretes en forma de lágrina enchapado en oro 18K, perla azul. C$285.00.png'),
          descripcion: "Aretes en forma de lágrina enchapado en oro 18K, perla azul.",
          seccion: "sección aretes",
          cantidad: 1
        },
        {
          id: 13,
          referencia: "ref: GBR-EJEW-D076-01D-GP",
          nombre: "Aretes colgantes",
          precio: "C$C$333.00",
          imagen: require('@/assets/img/aretes/13-GBR-EJEW-D076-01D-GP. Aretes colgantes de acero inoxidable 304 con forma de corazón doble para mujer, color dorado y acero inoxidable, 32,5 x 20 mm. C$333.00.png'),
          descripcion: "Aretes colgantes de acero inoxidable 304 con forma de corazón doble para mujer, color dorado y acero inoxidable, 32,5 x 20 mm.",
          seccion: "sección aretes",
          cantidad: 1
        },
        {
          id: 14,
          referencia: "ref: GBR-EJEW-D076-01F-EB",
          nombre: "Aretes de botón",
          precio: "C$333.00",
          imagen: require('@/assets/img/aretes/14-GBR-EJEW-D076-01F-EB. Aretes de botón de acero inoxidable 304 con doble corazón colgante para mujer, negro, 32,5 x 20 mm. C$333.00 .png'),
          descripcion: "Aretes de botón de acero inoxidable 304 con doble corazón colgante para mujer, negro, 32,5 x 20 mm.",
          seccion: "sección aretes",
          cantidad: 1
        },
        {
          id: 16,
          referencia: "ref: GBR-EJEW-E213-A-11G",
          nombre: "Aretes de perla",
          precio: "C$45.00",
          imagen: require('@/assets/img/aretes/16-GBR-EJEW-E213-A-11G. Aretes de perla 3mm en Acero Inoxidable. C$45.00 C-Par..png'),
          descripcion: "Aretes de perla 3mm en Acero Inoxidable. C$45.00, Cada Par.",
          seccion: "sección aretes",
          cantidad: 1
        },
        {
          id: 17,
          referencia: "ref: GBR-EJEW-E213-D-11G",
          nombre: "Aretes de perlas",
          precio: "C$55.00",
          imagen: require('@/assets/img/aretes/17-GBR-EJEW-E213-D-11G. Aretes de perlas 6mm en Acero Inoxidable. C$55.00 C-Par..png'),
          descripcion: "Aretes de perlas 6mm en Acero Inoxidable. C$55.00, Cada Par.",
          seccion: "sección aretes",
          cantidad: 1
        },
        {
          id: 18,
          referencia: "ref: GBR-EJEW-EE0001-232B",
          nombre: "Aretes enchapado en oro",
          precio: "C$295.00",
          imagen: require('@/assets/img/aretes/18-GBR-EJEW-EE0001-232B. Aretes enchapado en oro 18K, estilo lágrima y perla esperalda. C$295.00.png'),
          descripcion: "Aretes enchapado en oro 18K, estilo lágrima y perla esperalda.",
          seccion: "sección aretes",
          cantidad: 1
        },
        {
          id: 19,
          referencia: "ref: GBR-EJEW-EE0001-242B-03",
          nombre: "Aretes de libélula",
          precio: "C$298.00",
          imagen: require('@/assets/img/aretes/19-GBR-EJEW-EE0001-242B-03. Aretes de libélula con circonita cúbica bañado en oro real de 18 quilates, azul real, 43 x 14 mm. C$298.00.png'),
          descripcion: "Aretes de libélula con circonita cúbica bañado en oro real de 18 quilates, azul real, 43 x 14 mm.",
          seccion: "sección aretes",
          cantidad: 1
        },
        {
          id: 20,
          referencia: "ref: GBR-EJEW-EE0001-242B-03",
          nombre: "Aretes de libélula",
          precio: "C$298.00",
          imagen: require('@/assets/img/aretes/20-GBR-EJEW-EE0001-242B-03. Aretes de libélula con circonita cúbica bañado en oro real de 18 quilates, azul real, 43 x 14 mm. C$298.00.png'),
          descripcion: "Aretes de libélula con circonita cúbica bañado en oro real de 18 quilates, azul real, 43 x 14 mm.",
          seccion: "sección aretes",
          cantidad: 1
        },
        {
          id: 21,
          referencia: "ref: GBR-EJEW-EE0001-242B-03",
          nombre: "Aretes en Acero",
          precio: "C$85.00",
          imagen: require('@/assets/img/aretes/21-GBR-EJEW-F105-21P. Aretes en Acero Inoxidable, argolla redonda plateada. C$85.00.png'),
          descripcion: "Aretes en Acero Inoxidable, argolla redonda plateada.",
          seccion: "sección aretes",
          cantidad: 1
        },
        {
          id: 22,
          referencia: "ref: GBR-EJEW-EE0001-242B-03",
          nombre: "Aretes de argolla",
          precio: "C$205.00",
          imagen: require('@/assets/img/aretes/22-GBR-EJEW-F105-36P-COLORED. Aretes de argolla en Acero Inoxidable tejido con flores de colores. C$205.00.png'),
          descripcion: "Aretes de argolla en Acero Inoxidable tejido con flores de colores.",
          seccion: "sección aretes",
          cantidad: 1
        },
        {
          id: 23,
          referencia: "ref: GBR-EJEW-F282-42G",
          nombre: "Aretes de botón",
          precio: "C$205.00",
          imagen: require('@/assets/img/aretes/23-GBR-EJEW-F282-42G. Aretes de botón de flor de circonita cúbica con perla acrílica, joyería para mujer, chapado en oro real de 18 quilates, 10,5 x 11,5 mm, pasador 0,8 mm. C$205.00.png'),
          descripcion: "Aretes de botón de flor de circonita cúbica con perla acrílica, joyería para mujer, chapado en oro real de 18 quilates, 10,5 x 11,5 mm, pasador 0,8 mm.",
          seccion: "sección aretes",
          cantidad: 1
        },
        {
          id: 24,
          referencia: "ref: GBR-EJEW-F300-16P",
          nombre: "Aretes en Acero",
          precio: "C$195",
          imagen: require('@/assets/img/aretes/24-GBR-EJEW-F300-16P. Aretes en Acero Inoxidable plateado, corazón. C$195.png'),
          descripcion: "Aretes en Acero Inoxidable plateado, corazón.",
          seccion: "sección aretes",
          cantidad: 1
        },
        {
          id: 25,
          referencia: "ref: GBR-EJEW-FI0002-29-BH",
          nombre: "Aretes colgantes",
          precio: "C$455.00",
          imagen: require('@/assets/img/aretes/25-GBR-EJEW-FI0002-29-BH. Aretes colgantes de cristal con cuentas en forma de corazón, colores mezclados, 66x49mm, 1 parestilo. C$455.00 .png'),
          descripcion: "Aretes colgantes de cristal con cuentas en forma de corazón, colores mezclados, 66x49mm, 1 parestilo.",
          seccion: "sección aretes",
          cantidad: 1
        },
        {
          id: 27,
          referencia: "ref: GBR-EJEW-FI0002-29-MC",
          nombre: "Aretes colgantes",
          precio: "C$455.00",
          imagen: require('@/assets/img/aretes/27-GBR-EJEW-FI0002-29-MC. Aretes colgantes de cristal con cuentas en forma de corazón, colores mezclados, 66x49mm, 1 parestilo. C$455.00 .png'),
          descripcion: "Aretes colgantes de cristal con cuentas en forma de corazón, colores mezclados, 66x49mm, 1 parestilo.",
          seccion: "sección aretes",
          cantidad: 1
        },
        {
          id: 29,
          referencia: "ref: GBR-EJEW-FI0002-29-RC",
          nombre: "Aretes colgantes",
          precio: "C$455.00",
          imagen: require('@/assets/img/aretes/29-GBR-EJEW-FI0002-29-RC. Aretes colgantes de cristal con cuentas en forma de corazón, colores mezclados, 66x49mm, 1 parestilo. C$455.00 .png'),
          descripcion: "Aretes colgantes de cristal con cuentas en forma de corazón, colores mezclados, 66x49mm, 1 parestilo.",
          seccion: "sección aretes",
          cantidad: 1
        },
        {
          id: 31,
          referencia: "ref: GBR-EJEW-FI0002-29-VH",
          nombre: "Aretes colgantes",
          precio: "C$455.00",
          imagen: require('@/assets/img/aretes/31-GBR-EJEW-FI0002-29-VH. Aretes colgantes de cristal con cuentas en forma de corazón, colores mezclados, 66x49mm, 1 parestilo. C$455.00.png'),
          descripcion: "Aretes colgantes de cristal con cuentas en forma de corazón, colores mezclados, 66x49mm, 1 parestilo.",
          seccion: "sección aretes",
          cantidad: 1
        },
        {
          id: 32,
          referencia: "ref: GBR-EJEW-G311-02G",
          nombre: "Aretes de botón",
          precio: "C$235.00",
          imagen: require('@/assets/img/aretes/32-GBR-EJEW-G311-02G. Aretes de botón esmaltado con baño de oro real de 18 quilates para mujer, con cuentas de acrílico y tuercas para pendientes, con forma de corazón. C$235.00 .png'),
          descripcion: "Aretes de botón esmaltado con baño de oro real de 18 quilates para mujer, con cuentas de acrílico y tuercas para pendientes, con forma de corazón.",
          seccion: "sección aretes",
          cantidad: 1
        },
        {
          id: 34,
          referencia: "ref: GBR-EJEW-G312-11G",
          nombre: "Aretes de aro",
          precio: "C$265.00",
          imagen: require('@/assets/img/aretes/34-GBR-EJEW-G312-11G. Aretes de aro de tulipán con circonita transparente, joyería para mujer, chapado en oro real de 18 quilates, 24 x 15 x 7 mm, pasador 0,8 mm pasador 0,8 mm. C$265.00.png'),
          descripcion: "Aretes de aro de tulipán con circonita transparente, joyería para mujer, chapado en oro real de 18 quilates, 24 x 15 x 7 mm, pasador 0,8 mm pasador 0,8 mm.",
          seccion: "sección aretes",
          cantidad: 1
        },
        {
          id: 35,
          referencia: "ref: GBR-EJEW-G312-11G",
          nombre: "Aretes de aro",
          precio: "C$265.00",
          imagen: require('@/assets/img/aretes/35-GBR-EJEW-G312-11G. Aretes de aro de tulipán con circonita transparente, joyería para mujer, chapado en oro real de 18 quilates, 24 x 15 x 7 mm, pasador 0,8 mm pasador 0,8 mm. C$265.png'),
          descripcion: "Aretes de aro de tulipán con circonita transparente, joyería para mujer, chapado en oro real de 18 quilates, 24 x 15 x 7 mm, pasador 0,8 mm pasador 0,8 mm.",
          seccion: "sección aretes",
          cantidad: 1
        },
        {
          id: 36,
          referencia: "ref: GBR-EJEW-H115-04G",
          nombre: "Aretes colgantes",
          precio: "C$335.00",
          imagen: require('@/assets/img/aretes/36-GBR-EJEW-H115-04G, Aretes colgantes de chapado de larga duración con forma de corazón triple para mujer, chapado en oro real de 18 quilates, 61 x 20 mm, pasador 1 mm. C$335.00.png'),
          descripcion: "Aretes colgantes de chapado de larga duración con forma de corazón triple para mujer, chapado en oro real de 18 quilates, 61 x 20 mm, pasador 1 mm.",
          seccion: "sección aretes",
          cantidad: 1
        },
        {
          id: 37,
          referencia: "ref: GBR-EJEW-H115-04G",
          nombre: "Aretes colgantes",
          precio: "C$291.00",
          imagen: require('@/assets/img/aretes/37-GBR-EJEW-H115-04G. Aretes colgantes de acero inoxidable 304, flor, chapado en oro real de 14 quilates, 34,5 x 22,5 mm. C$291.00.png'),
          descripcion: "Aretes colgantes de acero inoxidable 304, flor, chapado en oro real de 14 quilates, 34,5 x 22,5 mm.",
          seccion: "sección aretes",
          cantidad: 1
        },
        {
          id: 39,
          referencia: "ref: GBR-EJEW-H115-08P",
          nombre: "Aretes de botón",
          precio: "C$249.00",
          imagen: require('@/assets/img/aretes/39-GBR-EJEW-H115-08P. Aretes de botón de acero inoxidable 304, lazo hueco, color plateado, 14 x 17 mm. C$249.00.png'),
          descripcion: "Aretes de botón de acero inoxidable 304, lazo hueco, color plateado, 14 x 17 mm.",
          seccion: "sección aretes",
          cantidad: 1
        },
        {
          id: 40,
          referencia: "ref: GBR-EJEW-H125-14A-G",
          nombre: "Aretes de botón",
          precio: "C$225.00",
          imagen: require('@/assets/img/aretes/40-GBR-EJEW-H125-14A-G. Aretes de botón esmaltado, con tuercas para pendientes, flor, chapado en oro real de 18 quilates, blanco. C$225.00.png'),
          descripcion: "Aretes de botón esmaltado, con tuercas para pendientes, flor, chapado en oro real de 18 quilates, blanco.",
          seccion: "sección aretes",
          cantidad: 1
        },
        {
          id: 42,
          referencia: "ref: GBR-EJEW-I245-03F",
          nombre: "Aretes en Acero",
          precio: "C$75.00",
          imagen: require('@/assets/img/aretes/42-GBR-EJEW-I245-03F. Aretes en Acero Inoxidabel argolla en forma ovalada. C$75.00.png'),
          descripcion: "Aretes en Acero Inoxidabel argolla en forma ovalada.",
          seccion: "sección aretes",
          cantidad: 1
        },
        {
          id: 43,
          referencia: "ref: GBR-EJEW-I245-27F",
          nombre: "Aretes en Acero",
          precio: "C$75.00",
          imagen: require('@/assets/img/aretes/43-GBR-EJEW-I245-27F. Aretes en acero Inoxidable argolla forma de corazón. C$75.00.png'),
          descripcion: "Aretes en acero Inoxidable argolla forma de corazón.",
          seccion: "sección aretes",
          cantidad: 1
        },
        {
          id: 44,
          referencia: "ref: GBR-EJEW-I263-02G",
          nombre: "Aretes de botón",
          precio: "C$225.00",
          imagen: require('@/assets/img/aretes/44-GBR-EJEW-I263-02G. Aretes de botón de gato y flor con circonita cúbica, pendientes de animales chapados en oro real de 18 quilates para mujer, azul. C$225.00.png'),
          descripcion: "Aretes de botón de gato y flor con circonita cúbica, pendientes de animales chapados en oro real de 18 quilates para mujer, azul.",
          seccion: "sección aretes",
          cantidad: 1
        },
        {
          id: 45,
          referencia: "ref: GBR-EJEW-M195-09G",
          nombre: "Earcuff ovalados",
          precio: "C$285.00",
          imagen: require('@/assets/img/aretes/45-GBR-EJEW-M195-09G. Earcuff ovalados, chapados en oro real de 18 quilates, 10 x 4,5 mm. C$285.00.png'),
          descripcion: "Earcuff ovalados, chapados en oro real de 18 quilates, 10 x 4,5 mm.",
          seccion: "sección aretes",
          cantidad: 1
        },
        {
          id: 47,
          referencia: "ref: GBR-EJEW-N011-60-BLUE",
          nombre: "Aretes enchapado",
          precio: "C$285.00",
          imagen: require('@/assets/img/aretes/47-GBR-EJEW-N011-60-BLUE. Aretes enchapado en oro 18K, flor dorada y perlas blancas con centro Azul. C$285.00.png'),
          descripcion: "Aretes enchapado en oro 18K, flor dorada y perlas blancas con centro Azul.",
          seccion: "sección aretes",
          cantidad: 1
        },
        {
          id: 48,
          referencia: "ref: GBR-EJEW-N011-60-GREEN",
          nombre: "Aretes enchapado",
          precio: "C$285.00",
          imagen: require('@/assets/img/aretes/48-GBR-EJEW-N011-60-GREEN. Aretes enchapado en oro 18K, flor dorada, perlas blancas y centro Verde. C$285.00.png'),
          descripcion: "Aretes enchapado en oro 18K, flor dorada, perlas blancas y centro Verde.",
          seccion: "sección aretes",
          cantidad: 1
        },
        {
          id: 49,
          referencia: "ref: GBR-EJEW-N011-60-PINK",
          nombre: "Aretes enchapado",
          precio: "C$285.00",
          imagen: require('@/assets/img/aretes/49-GBR-EJEW-N011-60-PINK. Aretes enchapado en oro 18K, flor dorada con perlas blancas y centro rosado. C$285.00.png'),
          descripcion: "Aretes enchapado en oro 18K, flor dorada con perlas blancas y centro rosado.",
          seccion: "sección aretes",
          cantidad: 1
        },
        {
          id: 50,
          referencia: "ref: GBR-EJEW-N011-60-WHITE",
          nombre: "Aretes enchapado",
          precio: "C$285.00",
          imagen: require('@/assets/img/aretes/50-GBR-EJEW-N011-60-WHITE. Aretes enchapado en oro 18K, flor dorada y perlas blancas, centro blanco. C$285.00.png'),
          descripcion: "Aretes enchapado en oro 18K, flor dorada y perlas blancas, centro blanco.",
          seccion: "sección aretes",
          cantidad: 1
        },
        {
          id: 51,
          referencia: "ref: GBR-EJEW-S208-037",
          nombre: "Aretes de botón",
          precio: "C$225.00",
          imagen: require('@/assets/img/aretes/51GBR-EJEW-S208-037. Aretes de botón con micropavé de circonita cúbica transparente, para religión, con cierres para pendientes, ovalados con virgen, chapado en oro. C$225.00.png'),
          descripcion: "Aretes de botón con micropavé de circonita cúbica transparente, para religión, con cierres para pendientes, ovalados con virgen, chapado en oro.",
          seccion: "sección aretes",
          cantidad: 1
        },
        {
          id: 52,
          referencia: "ref: GBR-KK-N216-540",
          nombre: "Accesorios para pendientes",
          precio: "C$295.00",
          imagen: require('@/assets/img/aretes/52-GBR-KK-N216-540. Accesorios para pendientes de botón con circonita cúbica, con bucles horizontales, Tuplipanes, dorado, verde. C$295.00.png'),
          descripcion: "Accesorios para pendientes de botón con circonita cúbica, con bucles horizontales, Tuplipanes, dorado, verde.",
          seccion: "sección aretes",
          cantidad: 1
        },
        {
          id: 53,
          referencia: "ref: GBR-KK-N232-118B-NF",
          nombre: "Aretes de botón",
          precio: "C$165.00",
          imagen: require('@/assets/img/aretes/53-GBR-KK-N232-118B-NF. Aretes de botón con micropavé de circonita cúbica transparente y pasadores de acero inoxidable, flor, Chapado en oro real de 18 quilates, carmesí. C$165.00.png'),
          descripcion: "Aretes de botón con micropavé de circonita cúbica transparente y pasadores de acero inoxidable, flor, Chapado en oro real de 18 quilates, carmesí.",
          seccion: "sección aretes",
          cantidad: 1
        },
        {
          id: 54,
          referencia: "ref: GBR-KK-R058-020G-BLACKPEARL",
          nombre: "Aretes de Acero",
          precio: "C$285.00",
          imagen: require('@/assets/img/aretes/54-GBR-KK-R058-020G-BLACKPEARL. Aretes de Acero Inoxidable Flor dorada y perlas negras y doradas. C$285.00.png'),
          descripcion: "Aretes de Acero Inoxidable Flor dorada y perlas negras y doradas.",
          seccion: "sección aretes",
          cantidad: 1
        },
        {
          id: 55,
          referencia: "ref: GBR-KK-R058-020G-WHITEPEARL",
          nombre: "Aretes de Acero",
          precio: "C$285.00",
          imagen: require('@/assets/img/aretes/55-GBR-KK-R058-020G-WHITEPEARL. Aretes de Acero Inoxidable con perla blanca y doradas, flor dorada. C$285.00.png'),
          descripcion: "Aretes de Acero Inoxidable con perla blanca y doradas, flor dorada.",
          seccion: "sección aretes",
          cantidad: 1
        },
        {
          id: 56,
          referencia: "ref: GBR-KK-S263-263-NF",
          nombre: "Aretes colgantes",
          precio: "C$255.00",
          imagen: require('@/assets/img/aretes/56-GBR-KK-S263-263-NF. Aretes colgantes enchapados en oro 18K, flor escarchada y centro de punta. C$255.00.png'),
          descripcion: "Aretes colgantes enchapados en oro 18K, flor escarchada y centro de punta.",
          seccion: "sección aretes",
          cantidad: 1
        },
        {
          id: 57,
          referencia: "ref: GBR-KK-S336-33G-B",
          nombre: "Aretes colgantes",
          precio: "C$285.00",
          imagen: require('@/assets/img/aretes/57-GBR-KK-S336-33G-B. Aretes en Acero Inoxidable, tejida con perlas negras y doradas. C$285.00.png'),
          descripcion: "Aretes en Acero Inoxidable, tejida con perlas negras y doradas.",
          seccion: "sección aretes",
          cantidad: 1
        },
        {
          id: 58,
          referencia: "ref: GBR-KK-S336-33G-R",
          nombre: "Aretes de Acero",
          precio: "C$285.00",
          imagen: require('@/assets/img/aretes/58-GBR-KK-S336-33G-R. Aretes de Acero Inoxidable, tejida en cristales rojos y perlas blancas. C$285.00.png'),
          descripcion: "Aretes de Acero Inoxidable, tejida en cristales rojos y perlas blancas.",
          seccion: "sección aretes",
          cantidad: 1
        },
        {
          id: 59,
          referencia: "ref: GBR-KK-S348-124",
          nombre: "Aretes de botón",
          precio: "C$285.00",
          imagen: require('@/assets/img/aretes/59-GBR-KK-S348-124. Aretes de botón chapados en oro real de 18 quilates corazón 12X38 pulgadas 13X11mm. pasador 0.7mm. C$155.00.png'),
          descripcion: "Aretes de botón chapados en oro real de 18 quilates corazón 12X38 pulgadas 13X11mm. pasador 0.7mm.",
          seccion: "sección aretes",
          cantidad: 1
        },
        {
          id: 60,
          referencia: "ref: GBR-KK-S348-421",
          nombre: "Aretes de botón",
          precio: "C$195.00",
          imagen: require('@/assets/img/aretes/60-GBR-KK-S348-421. Aretes enchapados en oro 18K, flor dorada y perla. C$195.00.png'),
          descripcion: "Aretes enchapados en oro 18K, flor dorada y perla.",
          seccion: "sección aretes",
          cantidad: 1
        },
        {
          id: 61,
          referencia: "ref: GBR-KK-S364-042",
          nombre: "Accesorios para pendientes",
          precio: "C$215.00",
          imagen: require('@/assets/img/aretes/61-GBR-KK-S364-042. Accesorios para pendientes de botón con micropavé de circonita cúbica, para cuentas perforadas a la mitad, chapado en oro real de 18 quilates. C$215.00.png'),
          descripcion: "Accesorios para pendientes de botón con micropavé de circonita cúbica, para cuentas perforadas a la mitad, chapado en oro real de 18 quilates.",
          seccion: "sección aretes",
          cantidad: 1
        },
        {
          id: 63,
          referencia: "ref: GBR-KK-S364-132",
          nombre: "Accesorios para pendientes",
          precio: "C$295.00",
          imagen: require('@/assets/img/aretes/63-GBR-KK-S364-132. Aretes enchapado en oro 18K, flor dorada y cadena colgante. C$295.00.png'),
          descripcion: "Aretes enchapado en oro 18K, flor dorada y cadena colgante.",
          seccion: "sección aretes",
          cantidad: 1
        },
        {
          id: 64,
          referencia: "ref: GBR-KK-T038-572C-G-NF",
          nombre: "Accesorios para pendientes",
          precio: "C$175.00",
          imagen: require('@/assets/img/aretes/64-GBR-KK-T038-572C-G-NF. Accesorios para pendientes de botón esmaltado, con aro, chapado en oro real de 18 quilates, flor, rojo. C$175.00.png'),
          descripcion: "Accesorios para pendientes de botón esmaltado, con aro, chapado en oro real de 18 quilates, flor, rojo.",
          seccion: "sección aretes",
          cantidad: 1
        },
        {
          id: 66,
          referencia: "ref: GBR-LS3119-2",
          nombre: "Pendientes colgantes",
          precio: "C$635.00",
          imagen: require('@/assets/img/aretes/66-GBR-LS3119-2. Pendientes colgantes de abanico con cuentas de cristal para mujeres, oro real de 18K chapado en acero inoxidable 304, color rojo, colgante 56x51mm. C$635.00.png'),
          descripcion: "Pendientes colgantes de abanico con cuentas de cristal para mujeres, oro real de 18K chapado en acero inoxidable 304, color rojo, colgante 56x51mm.",
          seccion: "sección aretes",
          cantidad: 1
        },
        {
          id: 68,
          referencia: "ref: GBR-STAS-D058-06G",
          nombre: "Pendientes colgantes",
          precio: "C$635.00",
          imagen: require('@/assets/img/aretes/68-GBR-STAS-D058-06G. Pendientes colgantes en forma de corazón con cuentas de cristal, colorido, 50mm. C$635.00.png'),
          descripcion: "Pendientes colgantes en forma de corazón con cuentas de cristal, colorido, 50mm.",
          seccion: "sección aretes",
          cantidad: 1
        },
        {
          id: 70,
          referencia: "ref: GBR-STAS-D058-08G",
          nombre: "Pendientes colgantes",
          precio: "C$635.00",
          imagen: require('@/assets/img/aretes/70-GBR-STAS-D058-08G. Pendientes colgantes planos redondos con cuentas de cristal, color rosa, 57.5mm. C$635.00.png'),
          descripcion: "Pendientes colgantes planos redondos con cuentas de cristal, color rosa, 57.5mm.",
          seccion: "sección aretes",
          cantidad: 1
        },
        {
          id: 71,
          referencia: "ref: GBR-STAS-D058-12G",
          nombre: "Pendientes colgantes",
          precio: "C$635.00",
          imagen: require('@/assets/img/aretes/71-GBR-STAS-D058-12G. Pendientes colgantes planos redondos con cuentas de cristal, acero inoxidable 304 con baño de vacío. Color verde mar, 61mm. C$635.00.png'),
          descripcion: "Pendientes colgantes planos redondos con cuentas de cristal, acero inoxidable 304 con baño de vacío. Color verde mar, 61mm.",
          seccion: "sección aretes",
          cantidad: 1
        },
        {
          id: 73,
          referencia: "ref: GBR-STAS-S105-T566D-29.5-2",
          nombre: "Aretes de Acero",
          precio: "C$295.00",
          imagen: require('@/assets/img/aretes/73-GBR-STAS-S105-T566D-29.5-2. Aretes de Acero Inoxidable, corazón dorado y perlas tejidas blancas. C$295.00.png'),
          descripcion: "Aretes de Acero Inoxidable, corazón dorado y perlas tejidas blancas.",
          seccion: "sección aretes",
          cantidad: 1
        },
        {
          id: 75,
          referencia: "ref: GBR-STAS-S105-T941-2",
          nombre: "Aretes de Acero",
          precio: "C$235.00",
          imagen: require('@/assets/img/aretes/75-GBR-STAS-S105-T941-2. Aretes de Acero Inoxidable con lágrima dorada y perlas blancas tejistas. C$235.00.png'),
          descripcion: "Aretes de Acero Inoxidable con lágrima dorada y perlas blancas tejistas.",
          seccion: "sección aretes",
          cantidad: 1
        },
        {
          id: 76,
          referencia: "ref: GBR-STAS-S105-T941-2.MOST",
          nombre: "Aretes de Acero",
          precio: "C$315.00",
          imagen: require('@/assets/img/aretes/76-GBR-STAS-S105-T941-2.MOST. Aretes de Acero Inoxidable, lágrima dorada tejida con mostacillas blancas y dorada. C$315.00.png'),
          descripcion: "Aretes de Acero Inoxidable, lágrima dorada tejida con mostacillas blancas y dorada.",
          seccion: "sección aretes",
          cantidad: 1
        },
        {
          id: 77,
          referencia: "ref: GBR-ZIRC-Q014-083G",
          nombre: "Aretes de botón",
          precio: "C$225.00",
          imagen: require('@/assets/img/aretes/77-GBR-ZIRC-Q014-083G. Aretes de botón con micropavé de circonita cúbica, con esmalte, abejas, sin níquel, chapado en oro real de 18 quilates. C$225.00.png'),
          descripcion: " Aretes de botón con micropavé de circonita cúbica, con esmalte, abejas, sin níquel, chapado en oro real de 18 quilates.",
          seccion: "sección aretes",
          cantidad: 1
        },
      ],
      mostrarModalCarrito: false,
      mostrarModalInfo: false,
      productoSeleccionado: null,
    }
  },
  methods: {
    incrementarCantidad(producto) {
      producto.cantidad++;
    },
    decrementarCantidad(producto) {
      if (producto.cantidad > 1) {
        producto.cantidad--;
      }
    },
    agregarAlCarrito(producto) {
      this.$store.commit('agregarAlCarrito', { ...producto });
      this.mostrarModalCarrito = true;
      setTimeout(() => {
        this.cerrarModalCarrito();
      }, 1000); // Modal se cierra automáticamente después de 1 segundo
    },
    abrirModalInfo(producto) {
      this.productoSeleccionado = producto;
      this.mostrarModalInfo = true;
    },
    cerrarModalCarrito() {
      this.mostrarModalCarrito = false;
    },
    cerrarModalInfo() {
      this.mostrarModalInfo = false;
      this.productoSeleccionado = null;
    }
  }
}
</script>



<style lang="scss">
@import "@/assets/styles/stylesProductos.scss";

.contenedor-titulos{
        position: relative;
        display: inline-block;
        text-align: center;
        display: flex;
        justify-content: center;

        img{
            height: 8rem;
            width: 40rem;
        }

        .texto{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;

            h2{
                color: $violeta-titulos;
                // font-family: 'Roboto', sans-serif;
                font-size: 2.8rem;
                // font-family: 'Crete Round', serif;
                font-family: 'Lobster', cursive;
                font-weight: 300;
                letter-spacing: 1px;
            }
        }

    }

.productos-container {
  padding: 10px;

  .productos {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    .producto {
      margin: 20px;

      .img-container {
        position: relative;

        img {
          height: 29rem;
          width: 24rem;
          object-fit: cover; // Asegura que la imagen mantenga sus proporciones dentro del contenedor
        }

        .icon-eye {
          position: absolute;
          bottom: 10px;
          right: 10px;
          background-color: rgba(0, 0, 0, 0.5);
          padding: 5px;
          border-radius: 50%;
          cursor: pointer;

          i {
            color: #fff;
            font-size: 18px;
          }
        }
      }

      // .ref, .nombre-producto, .precio, .cantidad, button {
      //   text-align: center;
      //   font-size: 16px;
      // }
    }
  }

  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    padding-top: 130px;

    .modal-content {
      background-color: #fff;
      padding: 35px 13px;
      border-radius: 8px;
      text-align: center;
      position: relative;
      max-width: 350px;
      width: 90%;
      // margin-top: 100px;

      p{
        color: $violeta;
        font-weight:700;
        font-size: 1.7rem;
        margin: -.5rem 0 .5rem 0;
      }

      li{
        font-size: 1.5rem;
      }

      img {
        width: 80%;
        height: 30rem;
        margin-bottom: 15px;
        object-fit: cover;
        // display: none;
      }

      .close {
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 24px;
        cursor: pointer;
      }
    }
  }

  @media (max-width: 768px) {
    .productos {
      flex-direction: column;
      align-items: center;

      .producto {
        width: 100%;
        margin: 10px 0;

        .img-container {
          img {
            width: 100%;  // Ajusta el ancho de la imagen según el contenedor
            height: auto; // Mantén la proporción de la imagen
          }
          .icon-eye {
            bottom: 5px;
            right: 5px;
            padding: 3px;
            i {
              font-size: 16px;
            }
          }
        }

        .ref, .nombre-producto, .precio, .cantidad, button {
          font-size: 14px;
        }
      }
    }

    .modal .modal-content {
      width: 95%;
      padding: 15px;
    }
  }

  @media (max-width: 700px) {
    .productos {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;

      .producto {
        width: 48%;
        margin: 10px 0;

        .img-container {
          img {
            width: 100%;  // Ajusta el ancho de la imagen según el contenedor
            height: auto; // Mantén la proporción de la imagen
          }
          .icon-eye {
            bottom: 5px;
            right: 5px;
            // padding: 2px;
            // i {
            //   font-size: 16px;
            // }
          }
        }

        .ref, .nombre-producto, .precio, .cantidad, button {
          font-size: 14px;
        }
      }
    }

    .modal{
      padding-top: 80px;

      .modal-content {
        width: 100%;
        padding: 10px;

      p{
        font-size: 2rem;
      }  

      li{
        margin: 1rem 0;
        font-size: 1.8rem;
      }
    }
    } 
  }

  @media (max-width: 450px) {
    .productos {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;

      .producto {
        width: 48%;
        margin: 5px 0;

        .img-container {
          .icon-eye {
            bottom: 3px;
            right: 3px;
            // padding: 2px;
            // i {
            //   font-size: 14px;
            // }
          }
        }

        .ref, .nombre-producto, .precio, .cantidad, button {
          font-size: 12px;
        }
      }
    }
  }

  @media (max-width: 400px){
    .modal .modal-content{
      width: 70%;

      img{
      width: 80%;  // Cambia el tamaño de la imagen al 70% del contenedor
      height: 25rem;
      padding:2rem 0 .5rem 0;
    }
    } 
  }
}
</style>





